<!-- 订单管理首页 -->
<template>
  <div>
    <div style="margin-top: 10px;margin: 23px;">订单管理</div>
    <div class="main" style="background: border-box;">
      <!-- <div style="font-size: 26px; font-weight: bold">订单列表</div> -->
      <div style="background: #fff;padding: 10px 0;border-radius: 8px;margin-bottom: 20px;">
        <div style="display: flex;">
          <div style="display: flex;flex-direction: column;width: 60%;color: #3D3D3D;">
          <div style="display: flex;align-items: center;">
            <div style="width: 120px;text-align: right;font-size: 16px;">搜索：</div>
            <el-input v-model="weTransactionId" placeholder="请输入订单号" class="cinput ac"
              @keyup.enter.native="list"></el-input>
          </div>
          <div style="display: flex;align-items: center;margin: 10px 0;">
            <div style="width: 120px;text-align: right;font-size: 16px;">订单状态：</div>
            <el-radio v-model="orderStatus" label="0">全部</el-radio>
            <el-radio v-model="orderStatus" label="1">待发货</el-radio>
            <el-radio v-model="orderStatus" label="2">已发货</el-radio>
            <el-radio v-model="orderStatus" label="3">运输中</el-radio>
            <el-radio v-model="orderStatus" label="4">已完成</el-radio>
            <el-radio v-model="orderStatus" label="5">取消订单</el-radio>
          </div>
          <div style="display: flex;justify-content: space-between;margin-bottom: 10px;">
            <div style="display: flex;align-items: center;">
              <div style="width: 120px;text-align: right;font-size: 16px;">品牌筛选：</div>
              <el-select v-model="brandId" placeholder="品牌筛选" clearable class="cinput ac" >
                <el-option v-for="item in brandList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </div>
            <div style="display: flex;align-items: center;">
              <div style="width: 120px;text-align: right;font-size: 16px;">类型筛选：</div>
              <el-select clearable v-model="categoryId" placeholder="类型筛选" class="cinput ac" style="margin: 0 23px"
                >
                <el-option v-for="item in classList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
          <!-- <div style="width: 221px">
            <el-input
              v-model="input"
              placeholder="请输入搜索内容"
              class="cinput ac"
              @keyup.enter.native="list"
            ></el-input>
            <div
              style="
                display: flex;
                height: 34px;
                width: 34px;
                align-items: center;
                background: #f7f7f7;
              "
            >
              <img
                v-if="isshow"
                src="../../images/search2.png"
                alt=""
                @click="list"
                style="background: #f7f7f7"
              />
              <img
                v-if="isshow == false"
                src="../../images/search1.png"
                alt=""
                style="background: #f7f7f7"
              />
            </div>
          </div> -->
        </div>
        </div>
        <div style="display: flex;justify-content: flex-end;margin-bottom: 10px;">
          <el-button
            
            style="height: 34px; line-height: 10px; margin-right: 10px;color: #00A78E;border-color: #00A78E;border-radius: 0;"
            class="cinput"
            @click='reset'
            >重置</el-button>
            <el-button
            type="primary"
            style="height: 34px; line-height: 10px; margin-right: 10px;color: #fff;background: #00A78E;border-radius: 0;"
            class="cinput"
            @click='searchStr'
            >查询</el-button>
          </div>
      </div>
      <div style="display: flex;flex-direction: column;padding: 10px;background: #fff;border-radius: 8px;">
        <div style="display: flex;justify-content: space-between;background: #fff;padding: 10px 0;">
            <div>订单列表</div>
            <div style="display: flex;align-items: center;">
              <el-button
            style="height: 34px; line-height: 10px; margin-right: 10px;color: #fff;background: #00A78E;border-radius: 0;"
            class="cinput"
            @click='exportExcel'
            >全部导出</el-button>
              <div style="font-size: 14px;color: #606266;">*根据筛选条件导出</div>
            </div>
          </div>
      <el-table :header-cell-style="{ background: '#F7F7F7' }" :data="tableData" style="width: 100%; min-height: 600px" 
        @selection-change="handleSelectionChange">
        <!-- <el-table-column type="selection" align='center' width="55" /> -->
        <el-table-column type="expand">
          <template slot-scope="scope">
            <el-form label-position="left" inline class="demo-table-expand" style="background: #f6f6f6;padding: 20px;">
              <div style="display: flex;width: 700px;">
                <el-form-item label="商品信息"></el-form-item>
                <el-form-item label="品牌：" style="width:20%">
                  <span>{{
                    scope.row.shopOrder.brandId == 1 ? '嘉顿肯妮' : scope.row.shopOrder.brandId == 2 ? '瑞妮朵拉' : '未定义'
                  }}</span>
                </el-form-item>
                <el-form-item label="价格：" style="width:20%">
                  <span>{{ scope.row.goodsInfo.priceMoney / 100 }}</span>
                </el-form-item>
                <el-form-item label="积分：" style="width:20%">
                  <span>{{ scope.row.goodsInfo.pricePoint }}</span>
                </el-form-item>
              </div>
              <div style="display: flex;width: 700px;">
                <el-form-item label="购买信息"></el-form-item>
                <el-form-item label="购买数量：" style="width:20%">
                  <span>{{ scope.row.shopOrder.count }}</span>
                </el-form-item>
                <el-form-item label="支付金额：" style="width:20%">
                  <span>{{ scope.row.shopOrder.payMoney / 100 }}</span>
                </el-form-item>
                <el-form-item label="支付积分：" style="width:20%">
                  <span>{{ scope.row.shopOrder.payPoint }}</span>
                </el-form-item>
                <el-form-item label="支付方式：" style="width:20%">
                  <span>{{
                    scope.row.shopOrder.payType == 1 ? '积分' : scope.row.shopOrder.payType == 2 ? '钱' : '积分+钱'
                  }}</span>
                </el-form-item>
              </div>
              <div style="display: flex;">
                <el-form-item label="地址信息"></el-form-item>
                <el-form-item label="城市：" >
                  <span>{{ scope.row.shopOrder.userAddress ? scope.row.shopOrder.userAddress.provinceName : '' }}{{
                    scope.row.shopOrder.userAddress ? scope.row.shopOrder.userAddress.cityName : ''
                  }}{{
  scope.row.shopOrder.userAddress ? scope.row.shopOrder.userAddress.areaName : ''
}}</span>
                </el-form-item>
                <el-form-item label="详细地址：">
                  <span>{{
                    scope.row.shopOrder.userAddress ? scope.row.shopOrder.userAddress.areaDetail : '未填写'
                  }}</span>
                </el-form-item>
              </div>
              <div style="display: flex;width: 700px;">
                <el-form-item label="物流信息"></el-form-item>
                <el-form-item label="物流单号：">
                  <span>{{ scope.row.shopOrder.logisticNum }}</span>
                </el-form-item>
              </div>

            </el-form>
          </template>
        </el-table-column>
        <el-table-column prop="pricePoint" align="center" label="订单状态">
          <template slot-scope="scope">
            <div>
              <div v-if="scope.row.shopOrder.payState == 1">
                {{
                  scope.row.shopOrder.payAfterState == -1 ? '退货' : scope.row.shopOrder.payAfterState == 0 ? '未发货' :
                    scope.row.shopOrder.payAfterState == 1 ? '收货' : scope.row.shopOrder.payAfterState == 2 ? '商家确认，准备发货' :
                      scope.row.shopOrder.payAfterState == 3 ? '运输中' : '未定义'
                }}
              </div>
              <div v-if="scope.row.shopOrder.payState != 1">
                {{
                  scope.row.shopOrder.payState == -2 ? '超时自动取消' : scope.row.shopOrder.payState == -1 ? '取消订单' :
                    scope.row.shopOrder.payState == 0 ? '未支付' : scope.row.shopOrder.payState == 1 ? '支付成功' :
                      scope.row.shopOrder.payState == 2 ? '支付积分待付钱' : '未定义'
                }}
              </div>
            </div>

          </template>
        </el-table-column>
        <el-table-column prop="pricePoint" align="center" label="订单编号">
          <template slot-scope="scope">
            <div>
              {{ scope.row.shopOrder.weTransactionId }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="priceMoney" align="center" label="缩略图" width="100">
          <template slot-scope="scope">
            <div>
              <img :src='scope.row.goodsInfo.thumbNailUrl' style="width:100px;height:100px" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="商品名称" align="center">
          <template slot-scope="scope">
            <div>
              {{ scope.row.goodsInfo.name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="pricePoint" align="center" label="用户" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div>
              {{ scope.row.shopOrder.userAddress.receiverName ? scope.row.shopOrder.userAddress.receiverName : '' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="pricePoint" align="center" label="电话" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div>
              {{ scope.row.shopOrder.userAddress.receiverPhone ? scope.row.shopOrder.userAddress.receiverPhone : '' }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="pricePoint" align="center" label="下单时间">
          <template slot-scope="scope">
            <div>
              {{ scope.row.shopOrder.ctime }}
            </div>
          </template>
        </el-table-column>

        <!-- <el-table-column prop="pricePoint" align="center" label="物流编号">
          <template slot-scope="scope">
            <div>
              {{ scope.row.shopOrder.logisticNum }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="priceMoney" align="center" label="商品价格" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div>
              {{ scope.row.goodsInfo.priceMoney / 100 }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="pricePoint" align="center" label="积分价格" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div>
              {{ scope.row.goodsInfo.pricePoint }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="stock" align="center" label="购买数量" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div>
              {{ scope.row.shopOrder.count }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="priceMoney" align="center" label="支付金钱" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div>
              {{ scope.row.shopOrder.payMoney / 100 }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="pricePoint" align="center" label="支付积分" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div>
              {{ scope.row.shopOrder.payPoint }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="pricePoint" align="center" label="支付方式" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div>
              {{ scope.row.shopOrder.payType == 1 ? '积分' : scope.row.shopOrder.payType == 2 ? '钱' : '积分+钱' }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="pricePoint" align="center" label="城市">
          <template slot-scope="scope">
            <div>
              {{ scope.row.shopOrder.userAddress ? scope.row.shopOrder.userAddress.provinceName : '' }}{{ scope.row.shopOrder.userAddress ? scope.row.shopOrder.userAddress.cityName : '' }}{{ scope.row.shopOrder.userAddress ? scope.row.shopOrder.userAddress.areaName : '' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="pricePoint" align="center" label="详细地址">
          <template slot-scope="scope">
            <div>
              {{ scope.row.shopOrder.userAddress ? scope.row.shopOrder.userAddress.areaDetail : '未填写' }}
            </div>
          </template>
        </el-table-column> -->



        <!-- <el-table-column prop="des" align="center" label="商品描述" :show-overflow-tooltip="true" >
        </el-table-column>
        <el-table-column prop="startOnlineTime" align="center" label="开始时间"  >
        </el-table-column>
        <el-table-column prop="endOnlineTime" align="center" label="结束时间" >
        </el-table-column> -->


        <el-table-column prop="address" label="操作" align="center" width="120" fixed="right">
          <template slot-scope="scope">
            <div style="display: flex; justify-content: center">
              <el-link type="primary"
                v-if="scope.row.shopOrder.payAfterState == 0 && (scope.row.shopOrder.payState == 2 || scope.row.shopOrder.payState == 1 || scope.row.shopOrder.payState == 0)"
                :underline="false" @click='dele(scope.row)' style="margin:0 20px">取消订单</el-link>
              <el-link type="primary" v-if="scope.row.shopOrder.payState == 1 && scope.row.shopOrder.payAfterState == 0"
                :underline="false" @click='details(scope.row)'>快递填写</el-link>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-table :header-cell-style="{ background: '#F7F7F7' }"  :data="tableDataAll" style="width: 100%; min-height: 600px;display: none;" id="out-table"
        @selection-change="handleSelectionChange">
        
        <el-table-column prop="pricePoint" align="center" label="订单状态">
          <template slot-scope="scope">
            <div>
              <div v-if="scope.row.shopOrder.payState == 1">
                {{
                  scope.row.shopOrder.payAfterState == -1 ? '退货' : scope.row.shopOrder.payAfterState == 0 ? '未发货' :
                    scope.row.shopOrder.payAfterState == 1 ? '收货' : scope.row.shopOrder.payAfterState == 2 ? '商家确认，准备发货' :
                      scope.row.shopOrder.payAfterState == 3 ? '运输中' : '未定义'
                }}
              </div>
              <div v-if="scope.row.shopOrder.payState != 1">
                {{
                  scope.row.shopOrder.payState == -2 ? '超时自动取消' : scope.row.shopOrder.payState == -1 ? '取消订单' :
                    scope.row.shopOrder.payState == 0 ? '未支付' : scope.row.shopOrder.payState == 1 ? '支付成功' :
                      scope.row.shopOrder.payState == 2 ? '支付积分待付钱' : '未定义'
                }}
              </div>
            </div>

          </template>
        </el-table-column>
        <el-table-column prop="pricePoint" align="center" label="订单编号">
          <template slot-scope="scope">
            <div>
              {{ scope.row.shopOrder.weTransactionId }}
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="priceMoney" align="center" label="缩略图" width="100">
          <template slot-scope="scope">
            <div>
              <img :src='scope.row.goodsInfo.thumbNailUrl' style="width:100px;height:100px" />
            </div>
          </template>
        </el-table-column> -->
        
        <el-table-column prop="name" label="商品名称" align="center">
          <template slot-scope="scope">
            <div>
              {{ scope.row.goodsInfo.name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="品牌" align="center">
          <template slot-scope="scope">
            <div>
              {{ scope.row.shopOrder.brandId == 1 ? '嘉顿肯妮' : scope.row.shopOrder.brandId == 2 ? '瑞妮朵拉' : scope.row.shopOrder.brandId == 3?'素颜+':'未定义' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="商品价格" align="center">
          <template slot-scope="scope">
            <div>
              {{ scope.row.goodsInfo.priceMoney / 100 }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="商品积分" align="center">
          <template slot-scope="scope">
            <div>
              {{ scope.row.goodsInfo.pricePoint }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="购买数量" align="center">
          <template slot-scope="scope">
            <div>
              {{ scope.row.shopOrder.count }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="支付价格" align="center">
          <template slot-scope="scope">
            <div>
              {{ scope.row.shopOrder.payMoney / 100 }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="支付积分" align="center">
          <template slot-scope="scope">
            <div>
              {{ scope.row.shopOrder.payPoint }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="pricePoint" align="center" label="用户" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div>
              {{ scope.row.shopOrder.userAddress.receiverName ? scope.row.shopOrder.userAddress.receiverName : '' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="pricePoint" align="center" label="电话" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div>
              {{ scope.row.shopOrder.userAddress.receiverPhone ? scope.row.shopOrder.userAddress.receiverPhone : '' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="pricePoint" align="center" label="城市" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div>
              {{ scope.row.shopOrder.userAddress ? scope.row.shopOrder.userAddress.provinceName : '' }}{{
                    scope.row.shopOrder.userAddress ? scope.row.shopOrder.userAddress.cityName : ''
                  }}{{
  scope.row.shopOrder.userAddress ? scope.row.shopOrder.userAddress.areaName : ''
}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="pricePoint" align="center" label="详细地址" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div>
              {{ scope.row.shopOrder.userAddress.areaDetail }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="pricePoint" align="center" label="下单时间">
          <template slot-scope="scope">
            <div>
              {{ scope.row.shopOrder.ctime }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="pricePoint" align="center" label="物流编号">
          <template slot-scope="scope">
            <div>
              {{ scope.row.shopOrder.logisticNum }}
            </div>
          </template>
        </el-table-column>

        <!-- <el-table-column prop="pricePoint" align="center" label="物流编号">
          <template slot-scope="scope">
            <div>
              {{ scope.row.shopOrder.logisticNum }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="priceMoney" align="center" label="商品价格" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div>
              {{ scope.row.goodsInfo.priceMoney / 100 }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="pricePoint" align="center" label="积分价格" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div>
              {{ scope.row.goodsInfo.pricePoint }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="stock" align="center" label="购买数量" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div>
              {{ scope.row.shopOrder.count }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="priceMoney" align="center" label="支付金钱" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div>
              {{ scope.row.shopOrder.payMoney / 100 }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="pricePoint" align="center" label="支付积分" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div>
              {{ scope.row.shopOrder.payPoint }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="pricePoint" align="center" label="支付方式" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div>
              {{ scope.row.shopOrder.payType == 1 ? '积分' : scope.row.shopOrder.payType == 2 ? '钱' : '积分+钱' }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="pricePoint" align="center" label="城市">
          <template slot-scope="scope">
            <div>
              {{ scope.row.shopOrder.userAddress ? scope.row.shopOrder.userAddress.provinceName : '' }}{{ scope.row.shopOrder.userAddress ? scope.row.shopOrder.userAddress.cityName : '' }}{{ scope.row.shopOrder.userAddress ? scope.row.shopOrder.userAddress.areaName : '' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="pricePoint" align="center" label="详细地址">
          <template slot-scope="scope">
            <div>
              {{ scope.row.shopOrder.userAddress ? scope.row.shopOrder.userAddress.areaDetail : '未填写' }}
            </div>
          </template>
        </el-table-column> -->



        <!-- <el-table-column prop="des" align="center" label="商品描述" :show-overflow-tooltip="true" >
        </el-table-column>
        <el-table-column prop="startOnlineTime" align="center" label="开始时间"  >
        </el-table-column>
        <el-table-column prop="endOnlineTime" align="center" label="结束时间" >
        </el-table-column> -->


        <!-- <el-table-column prop="address" label="操作" align="center" width="120" fixed="right">
          <template slot-scope="scope">
            <div style="display: flex; justify-content: center">
              <el-link type="primary"
                v-if="scope.row.shopOrder.payAfterState == 0 && (scope.row.shopOrder.payState == 2 || scope.row.shopOrder.payState == 1 || scope.row.shopOrder.payState == 0)"
                :underline="false" @click='dele(scope.row)' style="margin:0 20px">取消订单</el-link>
              <el-link type="primary" v-if="scope.row.shopOrder.payState == 1 && scope.row.shopOrder.payAfterState == 0"
                :underline="false" @click='details(scope.row)'>快递填写</el-link>
            </div>
          </template>
        </el-table-column> -->
      </el-table>
      <div style="display: flex;align-items: center;justify-content: flex-end;">
        <!-- <el-button type="primary" @click='deleall' :disabled='isdisabled'>删除选中</el-button> -->
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="pageIndex" :page-sizes="[10, 15, 20]" :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
      </div>
      
    </div>
    <el-dialog title="信息填写" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <el-form label-position="right" label-width="80px">
        <el-form-item label="快递名称">
          <el-input v-model="logisticName"></el-input>
        </el-form-item>
        <el-form-item label="快递单号">
          <el-input v-model="logisticNum"></el-input>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addCourier">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import FileSaver from "file-saver";
import * as XLSX from 'xlsx'
import { questiontypeList, qsList, levelList, qssaveOrUpdate, PaperList, delePaper, goodsList, goodsClassListAll, deleGoods, getorderList, cancelOrder, updateCourier } from "../../apis/index";
export default {
  data() {
    return {
      payAfterState:'',
      payState:'',
      orderStatus:'0',
      weTransactionId: '',
      logisticNum: '',
      logisticName: '',
      dialogVisible: false,
      classList: [],
      categoryId: '',
      brandList: [],
      brandId: '',
      date: '',
      timeState: '',
      timeStateList: [
        {
          id: 1,
          name: '未生效'
        },
        {
          id: 2,
          name: '已生效'
        },
        {
          id: 3,
          name: '已过期'
        },
      ],
      typeList: [
        {
          id: 1,
          name: '小程序'
        }, {
          id: 2,
          name: '课程包'
        }
      ],
      type: '',//问卷类型
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      qslist: [],
      levelLists: [],
      value: "",
      isshow: false,
      isshow2: true,
      isdisabled: true,
      currentPage: 1,
      radio: "1",
      input: "",
      tableData: [

      ],
      tableDataAll:[]
    };
  },
  watch: {
    // <template slot-scope="scope">
    //         <div>
    //           <div v-if="scope.row.shopOrder.payState == 1">
    //             {{
    //               scope.row.shopOrder.payAfterState == -1 ? '退货' : scope.row.shopOrder.payAfterState == 0 ? '未发货' :
    //                 scope.row.shopOrder.payAfterState == 1 ? '收货' : scope.row.shopOrder.payAfterState == 2 ? '商家确认，准备发货' :
    //                   scope.row.shopOrder.payAfterState == 3 ? '运输中' : '未定义'
    //             }}
    //           </div>
    //           <div v-if="scope.row.shopOrder.payState != 1">
    //             {{
    //               scope.row.shopOrder.payState == -2 ? '超时自动取消' : scope.row.shopOrder.payState == -1 ? '取消订单' :
    //                 scope.row.shopOrder.payState == 0 ? '未支付' : scope.row.shopOrder.payState == 1 ? '支付成功' :
    //                   scope.row.shopOrder.payState == 2 ? '支付积分待付钱' : '未定义'
    //             }}
    //           </div>
    //         </div>

    //       </template>
    orderStatus(newdata, olddata){//全部
      if(newdata==0){
        this.payAfterState='',
        this.payState=''
      }else if(newdata==1){//待发货
        this.payState=1
        this.payAfterState=0
      }else if(newdata==2){//已发货
        this.payState=1
        this.payAfterState=2
      }else if(newdata==3){//运输中
        this.payState=1
        this.payAfterState=3
      }else if(newdata==4){//已完成
        this.payState=1
        this.payAfterState=1
      }else if(newdata==5){//取消订单
        this.payState=-1
        this.payAfterState=0
      }
    },
    weTransactionId(newdata, olddata) {
      if (newdata == '') {
        this.list()
      }
    },
    input(newdata, olddata) {
      if (newdata == '') {
        this.isshow = false
        this.input = ''
        this.list()
      } else {
        this.isshow = true
      }
    },
    radio(newdata, olddata) {
      this.list()
    },
    value() {
      this.list()
    },
  },
  created() {
    this.brandList = this.$store.state.brandList
    this.getclassList()
    // let date=new Date().toLocaleString( )
    // let date1=date.replace(/\//g,'-')
    // this.date=new Date(date1).getTime()
    // this.questype()
    this.list()
    this.listAll()
    // this.leave()
  },
  //   beforeRouteLeave(to, from, next) {
  //   if(to.path=='/exercisesAdd'){//添加页，就不缓存
  //     this.$route.meta.keepAlive=false
  //     setTimeout(() => {
  //       next()
  //     }, 100);
  //   }else{//详情页缓存
  //    this.$route.meta.keepAlive=true
  //     setTimeout(() => {
  //       next()
  //     }, 100);
  //   }
  //   // 导航离开该组件的对应路由时调用
  //   // 可以访问组件实例 `this`
  // },
  filters: {
    filtersDate(date) {
      return new Date(date).getTime()
    },
  },
  methods: {
    searchStr(){
      this.list()
      this.listAll()
    },
    exportExcel() {//导出表格
            /* 从表生成工作簿对象 */
            // this.lists().then(res => {
                let wb = XLSX.utils.table_to_book(document.querySelector('#out-table'))
                /* 获取二进制字符串作为输出 */
                var wbout = XLSX.write(wb, {
                    bookType: 'xlsx',
                    bookSST: true,
                    type: 'array'
                })
                try {
                    FileSaver.saveAs(
                        //Blob 对象表示一个不可变、原始数据的类文件对象。
                        //Blob 表示的不一定是JavaScript原生格式的数据。
                        //File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
                        //返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
                        new Blob([wbout], { type: 'application/octet-stream' }),
                        //设置导出文件名称
                        '订单列表.xlsx'
                    )
                } catch (e) {
                    if (typeof console !== 'undefined') console.log(e, wbout)
                }
                return wbout
            // })

        },
    reset(){//重置搜索条件
      this.categoryId=this.brandId=this.weTransactionId=''
      this.pageIndex=1
      this.pageSize=10
      this.orderStatus='0'
      this.payAfterState='',
      this.payState=''
      this.list()
    },
    async getclassList() {//获取全部分类列表
      const { data } = await goodsClassListAll({ parentId: 0 })
      this.classList = data.data
    },
    look(item) {//查看统计
      console.log(item)
      let items = JSON.stringify(item)
      this.$router.push({ path: '/surveyPapgeDetails2', query: { items } })
    },
    async leave() {
      //获取等级列表
      const { data } = await levelList({ roleId: 3 });
      if (data.code == 200) {
        this.levelLists = data.data;
      } else {
        this.$message.error(data.msg);
      }
    },
    async list() {
      const params = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        brandId: this.brandId,
        categoryId: this.categoryId,
        weTransactionId: this.weTransactionId,
        payState:this.payState,
        payAfterState:this.payAfterState
        // timeState:this.timeState,
        // type:1
      }
      const { data } = await getorderList(params)
      if (data.code == 200) {
        //    let list=data.data.records.map(item=>{
        //      item.gettime=new Date(item.startTime).getTime()
        //      return item
        //    })
        // data.data.records.shopOrder.userAddress=JSON.parse(data.data.records.shopOrder.userAddress)
        data.data.records.forEach(item => {
          if (item.shopOrder.userAddress) {
            item.shopOrder.userAddress = JSON.parse(item.shopOrder.userAddress)
          }
        })
        console.log(data.data.records)
        this.tableData = data.data.records
        this.total = Number(data.data.total)
      }
    },
    async listAll() {
      const params = {
        pageIndex: 1,
        pageSize: 9999,
        brandId: this.brandId,
        categoryId: this.categoryId,
        weTransactionId: this.weTransactionId,
        payState:this.payState,
        payAfterState:this.payAfterState
        // timeState:this.timeState,
        // type:1
      }
      const { data } = await getorderList(params)
      if (data.code == 200) {
        //    let list=data.data.records.map(item=>{
        //      item.gettime=new Date(item.startTime).getTime()
        //      return item
        //    })
        // data.data.records.shopOrder.userAddress=JSON.parse(data.data.records.shopOrder.userAddress)
        data.data.records.forEach(item => {
          if (item.shopOrder.userAddress) {
            item.shopOrder.userAddress = JSON.parse(item.shopOrder.userAddress)
          }
        })
        console.log(data.data.records)
        this.tableDataAll = data.data.records
        // this.total = Number(data.data.total)
      }
    },
    async questype() {//题目类型列表
      const { data } = await questiontypeList();
      let qslist = data.data
      this.qslist = qslist;

    },
    add() {//添加题目
      this.$router.push({ path: '/mallAdd', query: { isadd: true } })
    },
    details(row) {//唤醒快递单填写
      this.dialogVisible = true
      this.id = row.shopOrder.id
      //   this.$router.push({path:'/mallAdd',query:{row:JSON.stringify(row)}})
    },
    async addCourier() {//提交快递
      const params = {
        id: this.id,
        logisticName: this.logisticName,
        logisticNum: this.logisticNum
      }
      const { data } = await updateCourier(params)
      if (data.code == 200) {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.dialogVisible = false
        this.pageIndex = 1,

          this.list()
      }
      console.log(params)
    },
    handleClose() {
      this.dialogVisible = false
    },
    details2(row) {//添加试题
      this.$router.push({ path: '/surveyPapgeAdd2', query: { row: JSON.stringify(row) } })
    },
    search() {
      console.log("我是搜索");
    },
    handleSelectionChange(val) {//选中
      console.log(val)
      if (val.length > 0) {
        this.isdisabled = false
      } else {
        this.isdisabled = true
      }
    },
    handleSizeChange(val) {
      this.pageSize = val
      console.log(`每页 ${val} 条`);
      this.list()
    },
    handleCurrentChange(val) {
      this.pageIndex = val
      console.log(`当前页: ${val}`);
      this.list()
    },
    dele(row) {
      const params = {
        id: row.shopOrder.id,
      }
      this.$confirm('此操作不可逆, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        cancelOrder(params).then(res => {
          if (res.data.code == 200) {
            this.$message({
              type: 'success',
              message: '操作成功!'
            });
            this.list()
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            });
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    deleall() {
      this.$confirm('此操作将永久删除选中项, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    }
  },
};
</script>

<style lang='less' scoped>
.main {
  // min-height: 800px;
  background: #fff;
  // margin: 19px 0;
  padding: 23px;
  border-radius: 14px;
}

.main>div:first-child {
  font-size: 26px;
  // font-weight: bold;
}

// .main>div:nth-child(2) {
//   display: flex;
//   justify-content: space-between;
//   height: 34px;
//   margin: 18px 0;

//   div {
//     display: flex;

//     img {
//       width: 25px;
//       height: 25px;
//     }
//   }
// }

/deep/.el-radio-button__inner {
  height: 34px;
  line-height: 10px;
}

.cinput /deep/.el-input__inner {
  height: 34px;
  line-height: 10px;
}

/deep/.el-button--primary {
  height: 34px;
  line-height: 10px;
}

/deep/.el-table::before {
  height: 0;
}

.ac /deep/.el-input__inner {
  // border: none;
  // background: #f7f7f7;
  // margin-right: 11px;
}

.red {
  color: #ff1b0b;
}
</style>
